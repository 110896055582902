body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.view-icon {
  cursor: pointer;
  color: rgba(27, 59, 101, 0.5);
  transition: color 0.3s ease;
  margin-right: 10px;
}

.view-icon:hover {
  color: rgb(27, 59, 101);
  transform: scale(1.1);
}

.edit-icon {
  cursor: pointer;
  color: rgba(27, 59, 101, 0.5);
  transition: color 0.3s ease;
  margin-right: 10px;
}

.edit-icon:hover {
  color: rgb(27, 59, 101);
  transform: scale(1.1);
}

.delete-icon {
  cursor: pointer;
  color: rgba(27, 59, 101, 0.5);
  transition: color 0.3s ease;
  margin-right: 10px;
}

.delete-icon:hover {
  color: red;
  transform: scale(1.1);
}

.pause-icon {
  cursor: pointer;
  color: rgba(27, 59, 101, 0.5);
  transition: color 0.3s ease;
  margin-right: 10px;
}

.pause-icon:hover {
  color: rgb(27, 59, 101);
  transform: scale(1.1);
}


.remove-icon {
  cursor: pointer;
  color: rgba(27, 59, 101, 0.5);
  transition: color 0.3s ease;
  margin-right: 10px;
}

.remove-icon:hover {
  color: red;
  transform: scale(1.1);
}

.add-icon {
  cursor: pointer;
  color: rgba(27, 59, 101, 0.5);
  transition: color 0.3s ease;
  margin-right: 10px;
}

.add-icon:hover {
  color: rgb(27, 59, 101);
  transform: scale(1.1);
}